import { AcpFeeplanModel, FeePlanResponse } from 'components/feeplan-domain';

import acpDebitCardTransfersCardComponent from './acp-debit-card-transfers-card-component';
import { acpDebitCardTransfersConfirmComponent } from './acp-debit-card-transfers-confirm-component';
import { acpDebitCardTransfersDetailsComponent } from './acp-debit-card-transfers-details-component';
import acpDebitCardTransfersEditComponent from './acp-debit-card-transfers-edit-component';
import { acpDebitCardTransfersEmailComponent } from './acp-debit-card-transfers-email-component';
import { acpDebitCardTransfersErrorComponent } from './acp-debit-card-transfers-error-component';
import { acpDebitCardTransfersManageComponent } from './acp-debit-card-transfers-manage-component';
import { acpDebitCardTransfersMoneyComponent } from './acp-debit-card-transfers-money-component';
import acpDebitCardTransfersPanCvcCheckComponent from './acp-debit-card-transfers-pan-cvc-check-component';
import { acpDebitCardTransfersTermsComponent } from './acp-debit-card-transfers-terms-component';
import acpDebitCardTransfersConfirmTemplate from './templates/acp-debit-card-transfers-confirm.html';
import { DCTErrorType } from './types/acp-debit-card-transfers-error-types';
import * as DCTTypes from './types/index';

export class AcpDebitCardTransfersService {
  public readonly ADD_NEW_CARD = 'ADDNEWCARD';

  public getPullLimit = this.webapiResource<
    void,
    DCTTypes.DebitCardTransfersLimitResponse
  >({
    method: 'GET',
    path: '/v2/debitcardtransfer/pull/limit'
  });

  public getPushLimit = this.webapiResource<
    void,
    DCTTypes.DebitCardTransfersLimitResponse
  >({
    method: 'GET',
    path: '/v2/debitcardtransfer/push/limit'
  });

  public getExternalAccounts = this.webapiResource<
    void,
    DCTTypes.DebitCardTransfersExternalAccountsResponse[]
  >({
    method: 'GET',
    path: '/v2/debitcardtransfer/cards',
    transformData: (externalAccounts) => {
      return externalAccounts.cards;
    }
  });

  public saveCardPush = this.webapiResource<
    DCTTypes.DebitCardTransfersSaveCardRequest,
    DCTTypes.DebitCardTransfersSaveCardResponse
  >({
    method: 'POST',
    path: '/v2/debitcardtransfer/push/card',
    format: 'json'
  });

  public saveCardPull = this.webapiResource<
    DCTTypes.DebitCardTransfersSaveCardRequest,
    DCTTypes.DebitCardTransfersSaveCardResponse
  >({
    method: 'POST',
    path: '/v2/debitcardtransfer/pull/card',
    format: 'json'
  });

  public pushTransfer = this.webapiResource<
    DCTTypes.DebitCardTransfersTransferRequest,
    DCTTypes.DebitCardTransfersTransferResponse
  >({
    method: 'POST',
    path: '/v2/debitcardtransfer/push/transfer',
    format: 'json'
  });

  public pullTransfer = this.webapiResource<
    DCTTypes.DebitCardTransfersTransferRequest,
    DCTTypes.DebitCardTransfersTransferResponse
  >({
    method: 'POST',
    path: '/v2/debitcardtransfer/pull/transfer',
    format: 'json'
  });

  public editNickname = this.webapiResource<
    DCTTypes.DebitCardTransfersEditNicknameRequest,
    DCTTypes.DebitCardTransfersEditNicknameResponse
  >({
    method: 'PUT',
    path: '/v2/debitcardtransfer/card/:id',
    format: 'json'
  });

  public deleteCard = this.webapiResource<
    DCTTypes.DebitCardTransfersEditNicknameRequest,
    DCTTypes.DebitCardTransfersEditNicknameResponse
  >({
    method: 'DELETE',
    path: '/v2/debitcardtransfer/card/:id',
    format: 'json'
  });

  private _cardId: string;
  private _last4Pan: number;
  private _isPullTransaction: boolean;
  private _saveCard: boolean = false;
  private _selectedCard: DCTTypes.DebitCardTransfersExternalCard; // card selected on Manage Cards Page
  private _fromManagePage: boolean = false; // As Save Card page can be reached from 2 pages we need to differentiate between them
  private _amount: number;
  private _fromText: string;
  private _toText: string;
  private _fromInd: number;
  private _toInd: number;
  private _canAddExternalCard: boolean;
  private _confirmNum: string;
  private _transferLoading: boolean;
  private _fees: FeePlanResponse['fees'];

  private transferInPageFlowConfig = {
    flowTitle: 'Debit Card Transfer',
    controllerAs: 'vm',
    suppressBackButton: false
  };

  private transferInPageFlowManageConfig = {
    flowTitle: 'Manage Saved Cards',
    controllerAs: 'vm',
    suppressBackButton: false
  };

  constructor(
    public nsInPageFlow: any,
    private webapiResource: nsWebclient.WebapiResourceService,
    private nsPermissions: nsUtils.NsPermissionsService,
    private acpSimpleAccountModel: any,
    private acpAuthModel: any,
    private acpCoreDispatcher: any,
    private acpFeeplanDomainModel: AcpFeeplanModel
  ) {
    'ngInject';
    this.fetchPermissions();
    this.fetchFees();
  }

  get cardId(): string {
    return this._cardId;
  }

  set cardId(value: string) {
    this._cardId = value;
  }

  get last4Pan(): number {
    return this._last4Pan;
  }

  set last4Pan(value: number) {
    this._last4Pan = value;
  }

  get isPullTransaction(): boolean {
    return this._isPullTransaction;
  }

  set isPullTransaction(value: boolean) {
    this._isPullTransaction = value;
  }

  get saveCard(): boolean {
    return this._saveCard;
  }

  set saveCard(value: boolean) {
    this._saveCard = value;
  }

  get selectedCard(): DCTTypes.DebitCardTransfersExternalCard {
    return this._selectedCard;
  }

  set selectedCard(value: DCTTypes.DebitCardTransfersExternalCard) {
    this._selectedCard = value;
  }

  get fromManagePage(): boolean {
    return this._fromManagePage;
  }

  set fromManagePage(value: boolean) {
    this._fromManagePage = value;
  }

  get amount(): number {
    return this._amount;
  }

  set amount(value: number) {
    this._amount = value;
  }

  get fromText(): string {
    return this._fromText;
  }

  set fromText(value: string) {
    this._fromText = value;
  }

  get toText(): string {
    return this._toText;
  }

  set toText(value: string) {
    this._toText = value;
  }

  get fromInd(): number {
    return this._fromInd;
  }

  set fromInd(value: number) {
    this._fromInd = value;
  }

  get toInd(): number {
    return this._toInd;
  }

  set toInd(value: number) {
    this._toInd = value;
  }

  get canAddExternalCard(): boolean {
    return this._canAddExternalCard;
  }

  set canAddExternalCard(value: boolean) {
    this._canAddExternalCard = value;
  }

  get confirmNum(): string {
    return this._confirmNum;
  }

  set confirmNum(value: string) {
    this._confirmNum = value;
  }

  get transferLoading(): boolean {
    return this._transferLoading;
  }

  set transferLoading(value: boolean) {
    this._transferLoading = value;
  }

  get fees(): FeePlanResponse['fees'] {
    return this._fees;
  }

  set fees(value: FeePlanResponse['fees']) {
    this._fees = value;
  }

  public enterEmail(): void {
    const config = {
      ...this.transferInPageFlowConfig,
      ...acpDebitCardTransfersEmailComponent
    };

    this.nsInPageFlow.open(config);
  }

  public enterTerms(): void {
    const config = {
      ...this.transferInPageFlowConfig,
      ...acpDebitCardTransfersTermsComponent
    };

    this.nsInPageFlow.open(config);
  }

  public enterMoneyTransfers(): void {
    const config = {
      ...this.transferInPageFlowConfig,
      ...acpDebitCardTransfersMoneyComponent
    };

    this.nsInPageFlow.open(config);
  }

  public enterManage(): void {
    const config = {
      ...this.transferInPageFlowManageConfig,
      ...acpDebitCardTransfersManageComponent
    };

    this.nsInPageFlow.open(config);
  }

  public flowIntoManage(): void {
    const config = {
      ...this.transferInPageFlowManageConfig,
      ...acpDebitCardTransfersManageComponent
    };

    this.nsInPageFlow.open(config);
  }

  public flowIntoTerms(): void {
    const config = {
      ...this.transferInPageFlowConfig,
      ...acpDebitCardTransfersTermsComponent
    };

    this.nsInPageFlow.push(config);
  }

  public flowIntoMoneyTransfers(): void {
    const config = {
      ...this.transferInPageFlowConfig,
      ...acpDebitCardTransfersMoneyComponent
    };

    this.nsInPageFlow.push(config);
  }

  public flowIntoCardInput(): void {
    const config = {
      ...this.transferInPageFlowConfig,
      ...acpDebitCardTransfersCardComponent
    };

    this.nsInPageFlow.push(config);
  }

  public flowIntoErrorPage(
    errorType?: DCTErrorType,
    dontSuppressBackBtn?: boolean
  ): void {
    const errorComponent = acpDebitCardTransfersErrorComponent(errorType);
    const config = {
      flowTitle: 'Debit Card Transfer',
      controllerAs: 'vm',
      suppressBackButton: !dontSuppressBackBtn,
      ...errorComponent
    };

    this.nsInPageFlow.push(config);
  }

  public flowIntoConfirmPage(): void {
    const config = {
      ...this.transferInPageFlowConfig,
      ...acpDebitCardTransfersConfirmComponent,
      suppressBackButton: true,
      template: acpDebitCardTransfersConfirmTemplate
    };

    this.nsInPageFlow.push(config);
  }

  public flowIntoTransferDetails(): void {
    const config = {
      ...this.transferInPageFlowConfig,
      ...acpDebitCardTransfersDetailsComponent
    };

    this.nsInPageFlow.push(config);
  }

  public flowIntoTransferEdit(): void {
    const config = {
      ...this.transferInPageFlowManageConfig,
      ...acpDebitCardTransfersEditComponent
    };

    this.nsInPageFlow.push(config);
  }

  public flowIntoPanCvcCheck(): void {
    const config = {
      ...this.transferInPageFlowConfig,
      ...acpDebitCardTransfersPanCvcCheckComponent
    };

    this.nsInPageFlow.push(config);
  }

  public async doPop(): Promise<void> {
    return await this.nsInPageFlow.pop();
  }

  public async closeFlow(): Promise<void> {
    return await this.nsInPageFlow.close();
  }

  public getInternalAccounts(): any[] {
    return this.acpSimpleAccountModel.get().then((data) => {
      return [data.account];
    });
  }

  public async fetchPermissions(): Promise<void> {
    this.canAddExternalCard = await this.nsPermissions.requestPermission(
      'canAddExternalCard'
    );
  }

  public async fetchFees(): Promise<void> {
    const { plan: { fees } } = await this.acpFeeplanDomainModel.get();
    this.fees = fees;
  }

  public getTransferErrorType(webapiError: string): DCTErrorType {
    let errorType: DCTErrorType;
    switch (webapiError) {
      case 'insufficient_funds':
      case 'service.provider.failure':
        errorType = DCTErrorType.CONTACT_CS;
        break;
      case 'service.provider.timeout':
      case 'request.timeout':
        errorType = DCTErrorType.TIMEOUT;
        break;
      default:
        errorType = DCTErrorType.UNSPECIFIED;
    }
    return errorType;
  }

  public async isCvcAuthed(): Promise<boolean> {
    const info = await this.acpAuthModel.getInfo();
    return info.card;
  }

  public async doTransfer(): Promise<void> {
    const trRequest: DCTTypes.DebitCardTransfersTransferRequest = {
      card_id: this.cardId,
      amount: this.amount * 100
    };
    this.transferLoading = true;

    try {
      let trResponse: DCTTypes.DebitCardTransfersTransferResponse = null;
      if (this.isPullTransaction) {
        trResponse = await this.pullTransfer(trRequest);
      } else {
        trResponse = await this.pushTransfer(trRequest);
      }
      this.confirmNum = trResponse.confirmation_number;
      this.acpCoreDispatcher.history.refresh.requested.emit(); // to refresh numbers on a dashboard
      this.flowIntoConfirmPage();
    } catch (error) {
      if (
        !error.error &&
        error._errors &&
        error._errors.length &&
        error._errors.indexOf('request.timeout') !== -1
      ) {
        // handle timeout error from acpApiResourceTimeoutInterceptor
        error.error = 'request.timeout';
      }
      const errorType: DCTErrorType = this.getTransferErrorType(error.error);
      this.flowIntoErrorPage(errorType);
    } finally {
      this.cardId = null;
      this.transferLoading = false;
    }
  }

  public getIndexById(arr, id): number {
    return arr.map((el) => el.id).indexOf(id);
  }

  public getBalance(options, id): string | null {
    return id >= 0 &&
      (options[id].available_balance || options[id].available_balance === 0)
      ? '' + options[id].available_balance / 100
      : null;
  }

  public addNameToAccounts(accountArr: any[]) {
    accountArr.forEach((account) => {
      account.external = true;
      account.name = account.nickname
        ? account.nickname
        : account.first_name + ' ' + account.last_name;
    });
    return accountArr;
  }

  // See source: https://stackoverflow.com/a/20460414
  public padZeros(last4Pan: number): string {
    if (last4Pan >= 1000) return '' + last4Pan;
    return ('000' + last4Pan).slice(-4);
  }

  public addNewDebitCardOption(options): void {
    options.push({
      id: this.ADD_NEW_CARD,
      name: '+ Add new debit card'
    });
  }
}
