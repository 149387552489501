import ng from 'angular';
import acpComponentCardownerDomain from 'components/cardowner-domain';
import acpFeePlanDomain from 'components/feeplan-domain';
import acpComponentPanCvcCheck from 'components/pan-cvc-check';
import acpCore from 'core';
import acpDebitCardTransfersCardComponent from './acp-debit-card-transfers-card-component';
import acpDebitCardTransfersConfirmComponent from './acp-debit-card-transfers-confirm-component';
import acpDebitCardTransfersDetailsComponent from './acp-debit-card-transfers-details-component';
import acpDebitCardTransfersEditComponent from './acp-debit-card-transfers-edit-component';
import acpDebitCardTransfersEmailComponent from './acp-debit-card-transfers-email-component';
import acpDebitCardTransfersIntroComponent from './acp-debit-card-transfers-intro-component';
import acpDebitCardTransfersManageComponent from './acp-debit-card-transfers-manage-component';
import { AcpDebitCardTransfersModel } from './acp-debit-card-transfers-model';
import acpDebitCardTransfersMoneyComponent from './acp-debit-card-transfers-money-component';
import acpDebitCardTransfersPanCvcCheckComponent from './acp-debit-card-transfers-pan-cvc-check-component';
import { AcpDebitCardTransfersService } from './acp-debit-card-transfers-service';
import acpDebitCardTransfersTermsComponent from './acp-debit-card-transfers-terms-component';
import acpDebitCardTransfersContent from './debit-card-transfers-content.yml';

import './styles/${theme}/core.scss';

export const acpDebitCardTransfersComponentModule = ng
  .module('acp.component.debit-card-transfers', [
    acpCore.name,
    acpComponentCardownerDomain.name,
    acpComponentPanCvcCheck.name,
    acpFeePlanDomain.name
  ])
  .service('acpDebitCardTransfersService', AcpDebitCardTransfersService)
  .service('acpDebitCardTransfersModel', AcpDebitCardTransfersModel)
  .component('acpDebitCardTransfersIntro', acpDebitCardTransfersIntroComponent)
  .component('acpDebitCardTransfersEmail', acpDebitCardTransfersEmailComponent)
  .component('acpDebitCardTransfersCard', acpDebitCardTransfersCardComponent)
  .component('acpDebitCardTransfersTerms', acpDebitCardTransfersTermsComponent)
  .component('acpDebitCardTransfersMoney', acpDebitCardTransfersMoneyComponent)
  .component(
    'acpDebitCardTransfersDetails',
    acpDebitCardTransfersDetailsComponent
  )
  .component(
    'acpDebitCardTransfersManage',
    acpDebitCardTransfersManageComponent
  )
  .component('acpDebitCardTransfersEdit', acpDebitCardTransfersEditComponent)
  .component(
    'acpDebitCardTransfersConfirm',
    acpDebitCardTransfersConfirmComponent
  )
  .component(
    'acpDebitCardTransfersPanCvcCheck',
    acpDebitCardTransfersPanCvcCheckComponent
  )
  .run((contentSectionCache) => {
    'ngInject';
    contentSectionCache.put(
      'components/debit-card-transfers',
      acpDebitCardTransfersContent
    );
  });
export * from './types';

export { acpDebitCardTransfersManageComponent };
export { default as acpDebitCardTransfersManageTemplate } from './templates/acp-debit-card-transfers-manage.html';
