import { AcpDebitCardTransfersService } from './acp-debit-card-transfers-service';
import acpDebitCardTransfersPanCvcCheckTemplate from './templates/acp-debit-card-transfers-pan-cvc-check.html';

export class AcpDebitCardTransfersPanCvcCheckComponentCtrl {
  public submittingCvc: boolean = false;
  public cvcAuthParams = {
    finally: () => (this.submittingCvc = false)
  };
  constructor(
    private acpCoreDispatcher: any,
    private acpCvcAuthenticationModel: any,
    private acpDebitCardTransfersService: AcpDebitCardTransfersService
  ) {
    'ngInject';
  }

  // this method was copied from "acp-pan-cvc-block-ctrl.js" and modified to not rely on signals
  public async submitCvc(): Promise<void> {
    this.acpCoreDispatcher.scrollTo.top.emit();
    this.submittingCvc = true;
    try {
      await this.acpCvcAuthenticationModel.authenticate(this.cvcAuthParams);
      this.acpDebitCardTransfersService.doTransfer();
    } catch (error) {
      // auth failed
      return;
    }
  }
}

export const acpDebitCardTransfersPanCvcCheckComponent: ng.IComponentOptions = {
  controller: AcpDebitCardTransfersPanCvcCheckComponentCtrl,
  controllerAs: 'vm',
  template: acpDebitCardTransfersPanCvcCheckTemplate
};

export default acpDebitCardTransfersPanCvcCheckComponent;
