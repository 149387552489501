import ng from 'angular';
import { AcpTermsModel } from 'core';
import { AcpDebitCardTransfersService } from './acp-debit-card-transfers-service';
import acpDebitCardTransfersTermsBottomSheetTemplate from './templates/acp-debit-card-transfers-terms-bottom-sheet.html';
import acpDebitCardTransfersTermsDialogTemplate from './templates/acp-debit-card-transfers-terms-dialog.html';
import acpDebitCardTransfersTermsTemplate from './templates/acp-debit-card-transfers-terms.html';
import KeyValuePair = nsUtils.KeyValuePair;

export class AcpDebitCardTransfersTermsComponentCtrl
  implements nsUtils.NsComponentController {
  private _isSubmitting: boolean = false;
  private _terms: KeyValuePair<boolean> = {
    esign: false,
    termsPp: false,
    consentToShare: false
  };
  private _termsConfirmed: boolean;
  private _today: Date = new Date();
  private _host: string = window.location.origin;

  constructor(
    private acpCoreDispatcher: any,
    private acpTermsModel: AcpTermsModel,
    private acpDebitCardTransfersService: AcpDebitCardTransfersService,
    private acpMedia: any,
    private $mdDialog: ng.material.IDialogService,
    private $mdBottomSheet: ng.material.IBottomSheetService
  ) {
    'ngInject';

    // See GB-761. Currently, life-cycle hooks do not work for nsInPageFlow.
  }

  public isInvalid(): boolean {
    return !this.allTermsAgreed();
  }

  public async submitTermsAgreement(): Promise<void> {
    this._isSubmitting = true;
    if (this.allTermsAgreed()) {
      this._termsConfirmed = true;
      this.acpCoreDispatcher.debitCardTransfers.payload.set(
        this._termsConfirmed
      );

      await this.acpTermsModel.acceptTerms('debit_card_transfer');

      this.acpDebitCardTransfersService.flowIntoMoneyTransfers();
      this._isSubmitting = false;
    }
  }

  public openTermsPopup() {
    const vm = this;
    const controllerPopUp = /*@ngInject*/ function($scope) {
      $scope.vm = vm;
    };
    if (this.acpMedia('mobile')) {
      this.$mdBottomSheet.show({
        clickOutsideToClose: false,
        template: acpDebitCardTransfersTermsBottomSheetTemplate,
        controller: controllerPopUp
      });
    } else {
      this.$mdDialog.show({
        parent: ng.element(document.body),
        template: acpDebitCardTransfersTermsDialogTemplate,
        controller: controllerPopUp
      });
    }
  }

  public closePopUp() {
    this.$mdDialog.hide();
    this.$mdBottomSheet.hide();
  }

  get terms(): KeyValuePair<boolean> {
    return this._terms;
  }

  set terms(value: KeyValuePair<boolean>) {
    this._terms = value;
  }

  get termsConfirmed(): boolean {
    return this._termsConfirmed;
  }

  set termsConfirmed(value: boolean) {
    this._termsConfirmed = value;
  }

  get isSubmitting(): boolean {
    return this._isSubmitting;
  }

  set isSubmitting(value: boolean) {
    this._isSubmitting = value;
  }

  get today(): Date {
    return this._today;
  }

  set today(value: Date) {
    this._today = value;
  }

  get host(): string {
    return this._host;
  }

  set host(value: string) {
    this._host = value;
  }

  private allTermsAgreed(): boolean {
    return Object.keys(this.terms).every((key) => {
      return this.terms[key] === true;
    });
  }
}

export const acpDebitCardTransfersTermsComponent: ng.IComponentOptions = {
  controller: AcpDebitCardTransfersTermsComponentCtrl,
  controllerAs: 'vm',
  template: acpDebitCardTransfersTermsTemplate,
  bindings: {},
  require: {}
};

export default acpDebitCardTransfersTermsComponent;
