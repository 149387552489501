import ng from 'angular';
import { AcpFeatureClient, AcpMedia, AcpTermsModel } from 'core';

import { AcpDebitCardTransfersService } from './acp-debit-card-transfers-service';
import acpDebitCardTransfersEsignBottomSheetTemplate from './templates/acp-debit-card-transfers-esign-bottom-sheet.html';
import acpDebitCardTransfersEsignDialogTemplate from './templates/acp-debit-card-transfers-esign-dialog.html';
import acpDebitCardTransfersIntroTemplate from './templates/acp-debit-card-transfers-intro.html';
import acpDebitCardTransfersTermsBottomSheetTemplate from './templates/acp-debit-card-transfers-terms-bottom-sheet.html';
import acpDebitCardTransfersTermsDialogTemplate from './templates/acp-debit-card-transfers-terms-dialog.html';
import { DebitCardTransfersExternalAccountsResponse } from './types/index';

export class AcpDebitCardTransfersIntroComponentCtrl
  implements nsUtils.NsComponentController {
  public loadingTerms: boolean = false;
  public externalAccounts: DebitCardTransfersExternalAccountsResponse[];
  private _profile: any;
  private _emailUpdated: boolean;
  private _loading: boolean = true;
  private _isDDA: boolean = false;

  constructor(
    nsComponentDecorator,
    private acpCoreDispatcher: any,
    private acpDebitCardTransfersService: AcpDebitCardTransfersService,
    private acpTermsModel: AcpTermsModel,
    private acpUserModel: any,
    private nsPermissions: nsUtils.NsPermissionsService,
    private acpFeatureClient: AcpFeatureClient,
    private $mdBottomSheet: ng.material.IBottomSheetService,
    private $mdDialog: ng.material.IDialogService,
    private acpMedia: AcpMedia
  ) {
    'ngInject';

    nsComponentDecorator(this, this);

    this.$onValue(this.acpCoreDispatcher.debitCardTransfers.listUpdated, () => {
      this.getExternalAccounts(), this.updateCanAddCardPermission();
    });
  }

  public $onValue?<T>(signal: nsUtils.NsSignal<T>, listener: (data: T) => void);

  public $onInit(): void {
    this._loading = false;
    this.signalListeners();
    this.getExternalAccounts();
    this.fetchPermissions();
  }

  public async beginDctFlow(): Promise<void> {
    if (this.isEmailNotPresent()) {
      this.acpDebitCardTransfersService.enterEmail();
    } else if (await this.isTermsNotAccepted()) {
      this.acpDebitCardTransfersService.enterTerms();
    } else {
      this.acpDebitCardTransfersService.enterMoneyTransfers();
    }
  }

  public async getExternalAccounts(): Promise<void> {
    try {
      this.externalAccounts = await this.acpDebitCardTransfersService.getExternalAccounts();
    } catch (errors) {
      this.acpDebitCardTransfersService.flowIntoErrorPage();
    }
  }

  public goToManagePage(): void {
    this.acpDebitCardTransfersService.flowIntoManage();
  }

  public openTermsPopUp() {
    this.openPopUp(
      acpDebitCardTransfersTermsBottomSheetTemplate,
      acpDebitCardTransfersTermsDialogTemplate
    );
  }

  public openEsignPopUp() {
    this.openPopUp(
      acpDebitCardTransfersEsignBottomSheetTemplate,
      acpDebitCardTransfersEsignDialogTemplate
    );
  }

  public closePopUp() {
    this.$mdDialog.hide();
    this.$mdBottomSheet.hide();
  }

  get profile(): any {
    return this._profile;
  }

  set profile(value: any) {
    this._profile = value;
  }

  get loading(): boolean {
    return this._loading;
  }

  get emailUpdated(): boolean {
    return this._emailUpdated;
  }

  set emailUpdated(value: boolean) {
    this._emailUpdated = value;
  }

  get isDDA(): boolean {
    return this._isDDA;
  }

  set isDDA(value: boolean) {
    this._isDDA = value;
  }

  private signalListeners(): void {
    this.$onValue(
      this.acpCoreDispatcher.profile.simpleAccount.updated,
      (profile) => {
        this.profile = profile;
      }
    );

    this.$onValue(this.acpCoreDispatcher.profile.user.edit.success, () => {
      this.acpUserModel.get().then((profile) => {
        this.profile = profile;
      });
    });
  }

  private isEmailNotPresent(): boolean {
    return this.profile && !this.profile.primary_card_owner.email;
  }

  private async isTermsNotAccepted(): Promise<boolean> {
    this.loadingTerms = true;
    const result = await this.acpTermsModel.getDebitCardTransfer();
    this.loadingTerms = false;
    return !result.accepted;
  }

  private async fetchPermissions(): Promise<void> {
    this._isDDA = await this.nsPermissions.requestPermission('isDDA');
    this.acpDebitCardTransfersService.canAddExternalCard = await this.nsPermissions.requestPermission(
      'canAddExternalCard'
    );
  }

  private async updateCanAddCardPermission(): Promise<void> {
    const featureResponse = await this.acpFeatureClient.getFeatureNoCache({
      feature: 'webapi:additionaldctcardallowed'
    });
    this.acpDebitCardTransfersService.canAddExternalCard =
      featureResponse.features['webapi:additionaldctcardallowed'];
  }

  private openPopUp(bottomSheetTemplate, dialogTemplate) {
    const vm = this;
    const controllerPopUp = /*@ngInject*/ function($scope) {
      $scope.vm = vm;
      $scope.vm.host = window.location.origin;
    };
    if (this.acpMedia('mobile')) {
      this.$mdBottomSheet.show({
        clickOutsideToClose: false,
        template: bottomSheetTemplate,
        controller: controllerPopUp
      });
    } else {
      this.$mdDialog.show({
        parent: ng.element(document.body),
        template: dialogTemplate,
        controller: controllerPopUp
      });
    }
  }
}

export const acpDebitCardTransfersIntro: ng.IComponentOptions = {
  controller: AcpDebitCardTransfersIntroComponentCtrl,
  controllerAs: 'vm',
  template: acpDebitCardTransfersIntroTemplate
};

export default acpDebitCardTransfersIntro;
