import ng from 'angular';
import { AcpDebitCardTransfersService } from './acp-debit-card-transfers-service';
import acpDebitCardTransfersManageTemplate from './templates/acp-debit-card-transfers-manage.html';
import {
  DebitCardTransfersExternalAccountsResponse,
  DebitCardTransfersExternalCard
} from './types/index';

export class AcpDebitCardTransfersManageComponentCtrl
  implements nsUtils.NsComponentController {
  public externalAccounts: DebitCardTransfersExternalAccountsResponse[];
  public isLoading: boolean = true;

  constructor(
    nsComponentDecorator,
    private $state: ng.ui.IStateService,
    private acpDebitCardTransfersService: AcpDebitCardTransfersService
  ) {
    'ngInject';
    nsComponentDecorator(this, this);
    this.getExternalAccounts();
  }

  public async getExternalAccounts(): Promise<void> {
    try {
      this.externalAccounts = await this.acpDebitCardTransfersService.getExternalAccounts();
    } catch (errors) {
      this.acpDebitCardTransfersService.flowIntoErrorPage();
    } finally {
      this.isLoading = false;

      if (this.externalAccounts && this.externalAccounts.length < 1) {
        this.$state.go('transfer-funds');
      }
    }
  }

  public addExternalCard() {
    this.acpDebitCardTransfersService.isPullTransaction = false;
    this.acpDebitCardTransfersService.fromManagePage = true;
    this.acpDebitCardTransfersService.flowIntoCardInput();
  }

  public showDetail(card: DebitCardTransfersExternalCard) {
    card.exp_date =
      card.exp_month > 10
        ? `${card.exp_month}${card.exp_year}`
        : `0${card.exp_month}${card.exp_year}`;
    card.name = `${card.first_name} ${card.last_name}`;
    card.pan = `000000000000${card.pan_last_4}`;

    this.acpDebitCardTransfersService.selectedCard = card;
    this.acpDebitCardTransfersService.flowIntoTransferEdit();
  }
}

export const acpDebitCardTransfersManageComponent: ng.IComponentOptions = {
  controller: AcpDebitCardTransfersManageComponentCtrl,
  controllerAs: 'vm',
  template: acpDebitCardTransfersManageTemplate
};

export default acpDebitCardTransfersManageComponent;
