import ng from 'angular';
import { User } from 'core';
import { AcpDebitCardTransfersService } from './acp-debit-card-transfers-service';
import acpDebitCardTransfersEmailTemplate from './templates/acp-debit-card-transfers-email.html';
import KeyValuePair = nsUtils.KeyValuePair;

export class AcpDebitCardTransfersEmailComponentCtrl
  implements nsUtils.NsComponentController {
  private _account: User;
  private _cvcAuthParams: any = {};
  private _isSubmitting: boolean = false;
  private _isCvcOrOobaAuthenticated: boolean = false;
  private _isEmailUpdateCompleted: boolean = false;
  private _userEmail: string;
  private dctEmailForm: ng.IFormController;

  constructor(
    private $log: ng.ILogService,
    private acpCvcAuthenticationModel: any,
    private acpAuthClient: any,
    private acpCardownerDomainModel: any,
    private acpCoreDispatcher: any,
    private acpDebitCardTransfersService: AcpDebitCardTransfersService,
    private acpUserModel: any
  ) {
    'ngInject';

    // See GB-761. Currently, life-cycle hooks do not work for nsInPageFlow.
    this.checkAuthentication();
    this.getUser();
  }

  public isInvalid(): boolean {
    return !this.dctEmailForm || this.dctEmailForm.$invalid;
  }

  public async submitEmail(): Promise<void> {
    this.isSubmitting = true;

    if (this.isEmailUpdateCompleted) {
      this.onSuccessProfileUpdate();
    } else {
      if (this.isCvcOrOobaAuthenticated) {
        this.sendEmailUpdate().then(
          this.onSuccessProfileUpdate.bind(this),
          this.onFailureProfileUpdate.bind(this)
        );
      } else {
        await this.acpCvcAuthenticationModel
          .authenticate(this.cvcAuthParams)
          .then(
            () => {
              this.isCvcOrOobaAuthenticated = true;

              this.sendEmailUpdate().then(
                this.onSuccessProfileUpdate.bind(this),
                this.onFailureProfileUpdate.bind(this)
              );
            },
            (err) => {
              this.$log.debug('err on auth in submit!', err);
              this.isSubmitting = false;
            }
          );
      }
    }
  }

  get isEmailUpdateCompleted(): boolean {
    return this._isEmailUpdateCompleted;
  }

  set isEmailUpdateCompleted(value: boolean) {
    this._isEmailUpdateCompleted = value;
  }

  get account(): User {
    return this._account;
  }

  set account(value: User) {
    this._account = value;
  }

  get cvcAuthParams(): any {
    return this._cvcAuthParams;
  }

  set cvcAuthParams(value: any) {
    this._cvcAuthParams = value;
  }

  get isCvcOrOobaAuthenticated(): boolean {
    return this._isCvcOrOobaAuthenticated;
  }

  set isCvcOrOobaAuthenticated(value: boolean) {
    this._isCvcOrOobaAuthenticated = value;
  }

  get userEmail(): string {
    return this._userEmail;
  }

  set userEmail(value: string) {
    this._userEmail = value;
  }

  get isSubmitting(): boolean {
    return this._isSubmitting;
  }

  set isSubmitting(value: boolean) {
    this._isSubmitting = value;
  }

  private async checkAuthentication(): Promise<KeyValuePair<boolean>> {
    return await this.acpAuthClient.info().then(
      (data) => {
        if (data && (data.cvc || data.ooba)) {
          this.isCvcOrOobaAuthenticated = true;
        }
      },
      (err) => {
        this.$log.debug('auth error!', err);
      }
    );
  }

  private async sendEmailUpdate(): Promise<void> {
    await this.acpCardownerDomainModel.edit(
      this.account.primary_card_owner.id,
      { email: this.userEmail }
    );
  }

  private goToNextPage() {
    this.acpDebitCardTransfersService.flowIntoTerms();

    if (!this.isEmailUpdateCompleted) {
      this.isEmailUpdateCompleted = true;
    }
    this.isSubmitting = false;
  }

  private onSuccessGetUser(data): void {
    this.account = data;
  }

  private async getUser(): Promise<void> {
    await this.acpUserModel.get().then(this.onSuccessGetUser.bind(this));
  }

  private onSuccessProfileUpdate(): void {
    if (this.userEmail) {
      this.acpCoreDispatcher.debitCardTransfers.payload.set(this.userEmail);
    }
    this.acpCoreDispatcher.profile.user.edit.success.emit(true);

    this.goToNextPage();
  }

  private onFailureProfileUpdate(): void {
    this.isSubmitting = false;
  }
}

export const acpDebitCardTransfersEmailComponent: ng.IComponentOptions = {
  controller: AcpDebitCardTransfersEmailComponentCtrl,
  controllerAs: 'vm',
  template: acpDebitCardTransfersEmailTemplate,
  bindings: {},
  require: {}
};

export default acpDebitCardTransfersEmailComponent;
