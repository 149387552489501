import ng from 'angular';
import { AcpDebitCardTransfersService } from './acp-debit-card-transfers-service';
import acpDebitCardTransfersCardTemplate from './templates/acp-debit-card-transfers-card.html';
import {
  DebitCardTransfersCardFormInput,
  DebitCardTransfersSaveCardRequest,
  DebitCardTransfersSaveCardResponse
} from './types';

import { DCTErrorType } from './types/acp-debit-card-transfers-error-types';

export class AcpDebitCardTransfersCardComponentCtrl
  implements nsUtils.NsComponentController {
  public isSubmitting: boolean = false;

  private _dctCardForm: ng.IFormController;
  private _cardInputs: DebitCardTransfersCardFormInput;

  constructor(
    private acpCoreDispatcher: any,
    private acpConfigurationService: any,
    private acpDebitCardTransfersService: AcpDebitCardTransfersService
  ) {
    'ngInject';
    // See GB-761. Currently, life-cycle hooks do not work for nsInPageFlow.
  }

  // TODO: AVG-2692
  public canScanCard(): boolean {
    return this.acpConfigurationService.getActivationShowScanCardRegistration();
  }

  public clearNickname(): void {
    if (this.cardInputs) {
      this.cardInputs.nickname = null;
    }
  }

  public isInvalid(): boolean {
    return !this.dctCardForm || this.dctCardForm.$invalid;
  }

  public async submitCard(): Promise<void> {
    if (this.isSubmitting || this.isInvalid()) {
      return;
    }

    this.isSubmitting = true;
    this.acpDebitCardTransfersService.last4Pan = this.cardInputs.pan % 10000;

    try {
      let saveCardResp: DebitCardTransfersSaveCardResponse;
      if (this.acpDebitCardTransfersService.isPullTransaction) {
        saveCardResp = await this.acpDebitCardTransfersService.saveCardPull(
          this.getSaveCardRequest()
        );
      } else {
        saveCardResp = await this.acpDebitCardTransfersService.saveCardPush(
          this.getSaveCardRequest()
        );
      }

      this.acpCoreDispatcher.debitCardTransfers.listUpdated.emit({}); // only Intro listens to it now

      /*
        If user came from Manage Saved Card page, go back to same page.
        If user came from Transfers page, go back to Transfers page.
        This is necessary to get around in-page-flow limitations and lack
        of lifecycle hooks within in-page-flow pages.
       */
      this.acpDebitCardTransfersService.closeFlow().then(() => {
        if (this.acpDebitCardTransfersService.fromManagePage) {
          // If user adds card from Manage Saved Card page, when arriving at Transfers page,
          // we will reset the "To" field
          this.acpDebitCardTransfersService.cardId = null;
          this.acpDebitCardTransfersService.enterManage();
        } else {
          // If user came from Transfers page, auto-complete the "To" field
          this.acpDebitCardTransfersService.cardId = saveCardResp.id;
          this.acpDebitCardTransfersService.enterMoneyTransfers();
        }
      });
    } catch (error) {
      const errorType: DCTErrorType = this.getErrorType(error.error);
      this.acpDebitCardTransfersService.flowIntoErrorPage(errorType, true);
    } finally {
      this.isSubmitting = false;
    }
  }

  private getErrorType(webapiError: string): DCTErrorType {
    let errorType: DCTErrorType;
    switch (webapiError) {
      case 'service.provider.failure':
        errorType = DCTErrorType.CONTACT_CS;
        break;
      default:
        errorType = DCTErrorType.UNSPECIFIED;
    }
    return errorType;
  }

  private getSaveCardRequest(): DebitCardTransfersSaveCardRequest {
    const saveCard: boolean =
      this._cardInputs.nickname &&
      this.acpDebitCardTransfersService.canAddExternalCard;
    const saveCardRequest: DebitCardTransfersSaveCardRequest = {
      pan: this._cardInputs.pan,
      expiration_month: parseInt(
        this._cardInputs.expDate[0] + this._cardInputs.expDate[1],
        10
      ),
      expiration_year: parseInt(
        this._cardInputs.expDate[2] + this._cardInputs.expDate[3],
        10
      ),
      cvv: this._cardInputs.cvc.toString()
    };
    if (saveCard) {
      saveCardRequest.save_card = true;
      saveCardRequest.nickname = this._cardInputs.nickname;
    }
    return saveCardRequest;
  }

  get cardInputs(): DebitCardTransfersCardFormInput {
    return this._cardInputs;
  }

  set cardInputs(value: DebitCardTransfersCardFormInput) {
    this._cardInputs = value;
  }

  get dctCardForm(): ng.IFormController {
    return this._dctCardForm;
  }

  set dctCardForm(value: ng.IFormController) {
    this._dctCardForm = value;
  }
}

export const acpDebitCardTransfersCardComponent: ng.IComponentOptions = {
  controller: AcpDebitCardTransfersCardComponentCtrl,
  controllerAs: 'vm',
  template: acpDebitCardTransfersCardTemplate
};

export default acpDebitCardTransfersCardComponent;
