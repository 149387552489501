import ng from 'angular';
import { AcpApiBrandingDomainClient, Program } from 'core';
import { AcpDebitCardTransfersService } from './acp-debit-card-transfers-service';
import acpDebitCardTransfersMoneyTemplate from './templates/acp-debit-card-transfers-money.html';
import {
  DCTTransactionEligibility,
  DebitCardTransfersExternalAccountsResponse,
  DebitCardTransfersExternalCard,
  DebitCardTransfersLimitResponse
} from './types';

export class AcpDebitCardTransfersMoneyComponentCtrl
  implements nsUtils.NsComponentController {
  isLoading = true;
  infoForm: ng.IFormController;
  pullLimit: string;
  pushLimit: string;
  pushLimitError: any;
  pullLimitError: any;
  trMaxValue: string;
  trFromOptions: any[];
  trToOptions: any[];
  internalAccounts: any[];
  externalAccounts: DebitCardTransfersExternalAccountsResponse[];
  amount: number;
  trFrom: DebitCardTransfersExternalCard;
  trTo: DebitCardTransfersExternalCard;
  trFromBalance: string = null;
  trToBalance: string = null;
  tempCardId: string = null;
  branding: Program = null;
  transferPrivilege: DCTTransactionEligibility = null;

  // TODO: MMT-67 will update minimum transfer amount as configurable value, not hard-coded.

  constructor(
    private acpDebitCardTransfersService: AcpDebitCardTransfersService,
    private acpApiBrandingDomainClient: AcpApiBrandingDomainClient,
    private nsPermissions: nsUtils.NsPermissionsService,
    private NS_PROGRAM_TYPES: any,
    nsComponentDecorator
  ) {
    'ngInject';
    nsComponentDecorator(this, this);
    this.fetchPermissions();
    this.acpDebitCardTransfersService.fetchFees();
    // See GB-761. Currently, life-cycle hooks do not work for nsInPageFlow.
  }

  async fetchPermissions(): Promise<void> {
    const permissionsResult: {
      [permissionId: string]: boolean;
    } = await this.nsPermissions.requestPermissions([
      'isPushDebitCardTransferEligible',
      'isPullDebitCardTransferEligible'
    ]);

    if (
      permissionsResult.isPushDebitCardTransferEligible &&
      !permissionsResult.isPullDebitCardTransferEligible
    ) {
      this.transferPrivilege = DCTTransactionEligibility.PUSH_ONLY;
    } else if (
      !permissionsResult.isPushDebitCardTransferEligible &&
      permissionsResult.isPullDebitCardTransferEligible
    ) {
      this.transferPrivilege = DCTTransactionEligibility.PULL_ONLY;
    } else {
      this.transferPrivilege = DCTTransactionEligibility.PUSH_AND_PULL;
    }

    this.getAllAccounts();
  }

  async getAllAccounts(): Promise<void> {
    // Get both types account sets, and make call for 2 things:
    // 1. branding (for modifying internal account names)
    // 2. get limits for both push and pull transfers
    try {
      [
        this.internalAccounts,
        this.externalAccounts,
        this.branding
      ] = await Promise.all([
        this.acpDebitCardTransfersService.getInternalAccounts(),
        this.acpDebitCardTransfersService.getExternalAccounts(),
        this.acpApiBrandingDomainClient.getApiBranding(),
        this.getLimits()
      ]);
    } catch (err) {
      this.acpDebitCardTransfersService.flowIntoErrorPage();
    }

    this.concatAccountLists(this.internalAccounts, this.externalAccounts);
    this.handleNewCard(); // preselect saved card from dropdown if user came back from Save Card Page
    this.setDropDownValues(); // setting values user selected before adding a new card

    this.isLoading = false;
  }

  concatAccountLists(
    internalAccounts: any[],
    externalAccounts: DebitCardTransfersExternalAccountsResponse[]
  ): void {
    // Modify external account names
    externalAccounts = this.acpDebitCardTransfersService.addNameToAccounts(
      externalAccounts
    );

    // Modify internal account depending on account type
    internalAccounts = this.addBrandingToAccounts(internalAccounts);

    // In case user added external card, but hasn't saved it
    if (
      this.acpDebitCardTransfersService.cardId &&
      this.acpDebitCardTransfersService.last4Pan &&
      this.acpDebitCardTransfersService.saveCard === false
    ) {
      this.addTempExternalCard(
        externalAccounts,
        this.acpDebitCardTransfersService.cardId,
        this.acpDebitCardTransfersService.last4Pan
      );
    }

    this.acpDebitCardTransfersService.addNewDebitCardOption(externalAccounts);

    if (this.transferPrivilege === DCTTransactionEligibility.PUSH_ONLY) {
      this.trFromOptions = [...internalAccounts];
      this.trToOptions = [...externalAccounts];
    } else if (this.transferPrivilege === DCTTransactionEligibility.PULL_ONLY) {
      this.trFromOptions = [...externalAccounts];
      this.trToOptions = [...internalAccounts];
    } else {
      this.trFromOptions = [...internalAccounts, ...externalAccounts];
      this.trToOptions = [...internalAccounts, ...externalAccounts];
    }
  }

  handleNewCard(): void {
    if (this.acpDebitCardTransfersService.cardId) {
      if (this.acpDebitCardTransfersService.isPullTransaction) {
        const indx = this.acpDebitCardTransfersService.getIndexById(
          this.trFromOptions,
          this.acpDebitCardTransfersService.cardId
        );
        this.trFrom = this.trFromOptions[indx];
        this.handleFromSelect();
      } else {
        const indx = this.acpDebitCardTransfersService.getIndexById(
          this.trToOptions,
          this.acpDebitCardTransfersService.cardId
        );
        this.trTo = this.trToOptions[indx];
      }
    }
  }

  handleToSelect(): void {
    const id = this.trTo.id;
    if (id === this.acpDebitCardTransfersService.ADD_NEW_CARD) {
      this.trTo = null;
      this.goToAddCardPage(false);
      return;
    }
    const indx = this.acpDebitCardTransfersService.getIndexById(
      this.trToOptions,
      id
    );
    this.trToBalance = this.acpDebitCardTransfersService.getBalance(
      this.trToOptions,
      indx
    );
  }

  handleFromSelect(): void {
    this.trFromBalance = null;
    this.trToBalance = null;
    if (this.trFrom.id === this.acpDebitCardTransfersService.ADD_NEW_CARD) {
      if (this.pullLimitError) {
        this.acpDebitCardTransfersService.flowIntoErrorPage();
      }
      this.trFrom = null;
      this.goToAddCardPage(true);
      return;
    }

    this.acpDebitCardTransfersService.isPullTransaction = this.trFrom.external;
    if (this.trFrom.external) {
      if (this.pullLimitError) {
        this.acpDebitCardTransfersService.flowIntoErrorPage();
      }
      this.trToOptions = [...this.internalAccounts];
    } else {
      if (this.pushLimitError) {
        this.acpDebitCardTransfersService.flowIntoErrorPage();
      }
      this.trFromBalance = this.pushLimit;
      const indx = this.acpDebitCardTransfersService.getIndexById(
        this.trToOptions,
        this.trFrom.id
      );
      this.trFromBalance = this.acpDebitCardTransfersService.getBalance(
        this.trFromOptions,
        indx
      );
      this.trToOptions = [...this.externalAccounts];
    }
    if (this.trToOptions.length > 1 || this.trToOptions[0].type) {
      this.trTo = this.trToOptions[0];
      const indx = this.acpDebitCardTransfersService.getIndexById(
        this.trFromOptions,
        this.trTo.id
      );
      this.trToBalance = this.acpDebitCardTransfersService.getBalance(
        this.trToOptions,
        indx
      );
    }
    this.trMaxValue = this.trFrom.external ? this.pullLimit : this.pushLimit;
  }

  handleNext(): void {
    this.acpDebitCardTransfersService.cardId = this.trFrom.external
      ? this.trFrom.id
      : this.trTo.id;
    this.acpDebitCardTransfersService.amount = this.amount / 100;

    if (this.acpDebitCardTransfersService.isPullTransaction) {
      this.acpDebitCardTransfersService.fromText =
        this.trFrom.nickname || 'Card ending in ' + this.trFrom.pan_last_4;
      this.acpDebitCardTransfersService.toText = this.trTo.name;
    } else {
      const extCardText =
        this.trTo.nickname || 'Card ending in ' + this.trTo.pan_last_4;
      this.acpDebitCardTransfersService.fromText = this.trFrom.name;
      this.acpDebitCardTransfersService.toText = extCardText;
    }

    this.acpDebitCardTransfersService.flowIntoTransferDetails();
  }

  isInvalid(): boolean {
    return this.infoForm && this.infoForm.$invalid;
  }

  private addBrandingToAccounts(internalAccounts: any[]): any[] {
    const programType: string = this.branding.type;
    const brandName: string = this.branding.brand.name;
    return internalAccounts.map((acc) => {
      if (
        programType === this.NS_PROGRAM_TYPES.CONSUMER_DDA ||
        programType === this.NS_PROGRAM_TYPES.BAW_DDA
      ) {
        acc.name = /account/i.test(brandName)
          ? brandName
          : brandName + ' Account';
      } else if (programType === this.NS_PROGRAM_TYPES.GPR) {
        acc.name = /card/i.test(brandName)
          ? brandName + ' Account'
          : brandName + ' Card Account';
      }
      return acc;
    });
  }

  private addTempExternalCard(savedExternalCards, id, last4pan): void {
    this.tempCardId = id;
    savedExternalCards.push({
      id,
      name: `Card ***${this.acpDebitCardTransfersService.padZeros(last4pan)}`,
      external: true,
      pan_last_4: this.acpDebitCardTransfersService.padZeros(last4pan)
    });
  }

  private goToAddCardPage(isPull: boolean): void {
    this.acpDebitCardTransfersService.isPullTransaction = isPull;

    // Removing temp card from a card list as if user goes to add card page he probably wants to add a different card
    if (this.tempCardId) this.removeTempCard();
    this.getDropDownValues();

    this.acpDebitCardTransfersService.cardId = null;
    this.acpDebitCardTransfersService.saveCard = false;
    this.acpDebitCardTransfersService.fromManagePage = false;
    this.acpDebitCardTransfersService.flowIntoCardInput();
  }

  private setDropDownValues(): void {
    const sourceCardIndex = this.acpDebitCardTransfersService.fromInd;
    const destinationCardIndex = this.acpDebitCardTransfersService.toInd;

    if (sourceCardIndex >= 0) {
      this.trFrom = this.trFromOptions[sourceCardIndex];
      this.trFromBalance = this.acpDebitCardTransfersService.getBalance(
        this.trFromOptions,
        sourceCardIndex
      );

      // If and only if user is both push and pull eligible, then remove the
      // source ("From" field) card from the destination ("To" field) card options.
      if (this.transferPrivilege === DCTTransactionEligibility.PUSH_AND_PULL) {
        this.trToOptions.splice(sourceCardIndex, 1);
      }
    }

    if (destinationCardIndex >= 0) {
      this.trTo = this.trToOptions[destinationCardIndex];
      this.trToBalance = this.acpDebitCardTransfersService.getBalance(
        this.trToOptions,
        destinationCardIndex
      );
    }
    if (this.trFrom) {
      this.trMaxValue = this.trFrom.external ? this.pullLimit : this.pushLimit;
    }
  }

  private getDropDownValues(): void {
    if (this.trFrom) {
      this.acpDebitCardTransfersService.fromInd = this.acpDebitCardTransfersService.getIndexById(
        this.trFromOptions,
        this.trFrom.id
      );
    }
    if (this.trTo) {
      this.acpDebitCardTransfersService.toInd = this.acpDebitCardTransfersService.getIndexById(
        this.trToOptions,
        this.trTo.id
      );
    }
  }

  private removeTempCard(): void {
    const inExtListId = this.acpDebitCardTransfersService.getIndexById(
      this.externalAccounts,
      this.tempCardId
    );
    if (inExtListId >= 0) this.externalAccounts.splice(inExtListId, 1);

    if (this.trFrom && this.trFrom.id === this.tempCardId) this.trFrom = null;
    if (this.trTo && this.trTo.id === this.tempCardId) this.trTo = null;

    const inFromListId = this.acpDebitCardTransfersService.getIndexById(
      this.trFromOptions,
      this.tempCardId
    );
    const inToListId = this.acpDebitCardTransfersService.getIndexById(
      this.trToOptions,
      this.tempCardId
    );
    if (inFromListId >= 0) this.trFromOptions.splice(inFromListId, 1);
    if (inToListId >= 0) this.trToOptions.splice(inToListId, 1);
    this.tempCardId = null;
  }

  private async getLimits(): Promise<void> {
    let pushLimit: void | DebitCardTransfersLimitResponse = null;
    let pullLimit: void | DebitCardTransfersLimitResponse = null;

    // Because we fetch permissions first, we can now restrict which limit calls
    // to perform without fear of undefined push/pull eligibilities.
    if (this.transferPrivilege === DCTTransactionEligibility.PUSH_AND_PULL) {
      [pushLimit, pullLimit] = await Promise.all([
        this.acpDebitCardTransfersService.getPushLimit().catch((error) => {
          this.pushLimitError = error;
        }),
        this.acpDebitCardTransfersService.getPullLimit().catch((error) => {
          this.pullLimitError = error;
        })
      ]);
    } else if (this.transferPrivilege === DCTTransactionEligibility.PUSH_ONLY) {
      pushLimit = await this.acpDebitCardTransfersService
        .getPushLimit()
        .catch((error) => {
          this.pushLimitError = error;
        });
    } else if (this.transferPrivilege === DCTTransactionEligibility.PULL_ONLY) {
      pullLimit = await this.acpDebitCardTransfersService
        .getPullLimit()
        .catch((error) => {
          this.pullLimitError = error;
        });
    }

    if (pushLimit) {
      this.pushLimit = '' + pushLimit.limit / 100;
    }
    if (pullLimit) {
      this.pullLimit = '' + pullLimit.limit / 100;
    }
  }
}

export const acpDebitCardTransfersMoneyComponent: ng.IComponentOptions = {
  controller: AcpDebitCardTransfersMoneyComponentCtrl,
  controllerAs: 'vm',
  template: acpDebitCardTransfersMoneyTemplate
};

export default acpDebitCardTransfersMoneyComponent;
