import { AcpFeeplanModel } from 'components/feeplan-domain';
import { AcpDebitCardTransfersService } from './acp-debit-card-transfers-service';
import AcpDebitCardTransfersDetailsTemplate from './templates/acp-debit-card-transfers-details.html';

export class AcpDebitCardTransfersDetailsComponentCtrl
  implements nsUtils.NsComponentController {
  public isSending: boolean = false;
  public fee: string;

  constructor(
    public acpDebitCardTransfersService: AcpDebitCardTransfersService,
    public acpFeeplanDomainModel: AcpFeeplanModel
  ) {
    'ngInject';
    this.getFee();
  }

  public getFee(): void {
    if (this.acpDebitCardTransfersService.isPullTransaction) {
      this.fee = this.acpDebitCardTransfersService.fees.dct_pull_transaction.flat_fee;
    } else {
      this.fee = this.acpDebitCardTransfersService.fees.dct_push_transaction.flat_fee;
    }
  }

  public async transfer(): Promise<void> {
    this.isSending = true;
    const isCvcAuthed = await this.acpDebitCardTransfersService.isCvcAuthed();
    if (this.acpDebitCardTransfersService.amount > 50 && !isCvcAuthed) {
      this.acpDebitCardTransfersService.flowIntoPanCvcCheck();
      this.isSending = false;
    } else {
      await this.acpDebitCardTransfersService.doTransfer();
    }
  }

  public cancelFlow(): void {
    this.acpDebitCardTransfersService.closeFlow();
  }
}

export const acpDebitCardTransfersDetailsComponent: ng.IComponentOptions = {
  controller: AcpDebitCardTransfersDetailsComponentCtrl,
  controllerAs: 'vm',
  template: AcpDebitCardTransfersDetailsTemplate
};

export default acpDebitCardTransfersDetailsComponent;
