import ng from 'angular';
import { AcpDebitCardTransfersService } from './acp-debit-card-transfers-service';
import { DebitCardTransfersLimitResponse } from './types/index';

export class AcpDebitCardTransfersModel {
  private $log: ng.ILogService;

  constructor(
    private acpCvcAuthenticationModel: any,
    private acpDebitCardTransfersService: AcpDebitCardTransfersService
  ) {
    'ngInject';
  }

  public async cvcAuth(cvcAuthParams): Promise<void> {
    try {
      return await this.acpCvcAuthenticationModel.authenticate(cvcAuthParams);
    } catch (err) {
      this.$log.debug('CVC authenticate for DCT error: ', err);
      throw err;
    }
  }

  public async getPullLimit(): Promise<DebitCardTransfersLimitResponse> {
    try {
      return await this.acpDebitCardTransfersService.getPullLimit();
    } catch (err) {
      this.$log.debug('Service getPullLimit for DCT error: ', err);
      throw err;
    }
  }

  public async getPushLimit(): Promise<DebitCardTransfersLimitResponse> {
    try {
      return await this.acpDebitCardTransfersService.getPushLimit();
    } catch (err) {
      this.$log.debug('Service getPushLimit for DCT error: ', err);
      throw err;
    }
  }
}
