import ng from 'angular';
import { AcpMedia } from 'core';
import { AcpDebitCardTransfersService } from './acp-debit-card-transfers-service';
import acpDCTDeleteBottomSheetTemplate from './templates/acp-debit-card-transfers-delete-bottom-sheet.html';
import acpDCTDeleteTemplate from './templates/acp-debit-card-transfers-delete-dialog.html';
import acpDebitCardTransfersEditTemplate from './templates/acp-debit-card-transfers-edit.html';
import { DebitCardTransfersExternalCard } from './types/index';

export class AcpDebitCardTransfersEditComponentCtrl
  implements nsUtils.NsComponentController {
  public isSubmitting: boolean = false;
  public selectedCard: DebitCardTransfersExternalCard = null;
  public editDisabled: boolean = true;
  public panFocus: boolean = true;
  public panDisabled: boolean = false;
  public dctNicknameForm: ng.IFormController;

  constructor(
    nsComponentDecorator,
    private nsInPageFlow,
    private acpMedia: AcpMedia,
    private acpCoreDispatcher: any,
    private $mdBottomSheet: ng.material.IBottomSheetService,
    private $mdDialog: angular.material.IDialogService,
    private acpDebitCardTransfersService: AcpDebitCardTransfersService
  ) {
    'ngInject';
    nsComponentDecorator(this, this);
    this.panDisabled = true;
    this.selectedCard = (Object as any).assign(
      {},
      this.acpDebitCardTransfersService.selectedCard
    );
  }

  public async save(): Promise<void> {
    this.isSubmitting = true;

    try {
      await this.acpDebitCardTransfersService.editNickname({
        id: this.selectedCard.id,
        nickname: this.selectedCard.nickname
      });

      this.acpDebitCardTransfersService.closeFlow().then(() => {
        this.acpDebitCardTransfersService.enterManage();
      });
    } catch (ex) {
      this.acpDebitCardTransfersService.flowIntoErrorPage();
    } finally {
      this.isSubmitting = false;
    }
  }

  public initiateDelete(): void {
    const vm = this;
    const controllerDeletePopUp = /*@ngInject*/ function($scope) {
      $scope.vm = vm;
    };
    if (this.acpMedia('mobile')) {
      this.$mdBottomSheet.show({
        template: acpDCTDeleteBottomSheetTemplate,
        controller: controllerDeletePopUp
      });
    } else {
      this.$mdDialog.show({
        template: acpDCTDeleteTemplate,
        controller: controllerDeletePopUp
      });
    }
  }

  public async deleteCard(): Promise<void> {
    this.isSubmitting = true;

    this.closeDialog();

    try {
      // TODO: See AVG-3898 for updating typings here. We don't need nickname for deleteCard endpoint.
      await this.acpDebitCardTransfersService.deleteCard({
        id: this.selectedCard.id,
        nickname: this.selectedCard.nickname
      });

      this.acpDebitCardTransfersService.closeFlow().then(() => {
        this.acpCoreDispatcher.debitCardTransfers.listUpdated.emit({});
        this.acpDebitCardTransfersService.enterManage();
      });
    } catch (ex) {
      this.acpDebitCardTransfersService.flowIntoErrorPage();
    } finally {
      this.isSubmitting = false;
    }
  }

  public isInvalid(): boolean {
    return (
      this.editDisabled ||
      this.dctNicknameForm.$pristine ||
      this.dctNicknameForm.$invalid
    );
  }

  public changeNickname(): void {
    this.editDisabled = !this.editDisabled;
  }

  public cancel(): void {
    this.nsInPageFlow.pop();
  }

  public closeDialog(): void {
    this.$mdDialog.hide();
    this.$mdBottomSheet.hide();
  }
}

export const acpDebitCardTransfersEditComponent: ng.IComponentOptions = {
  controller: AcpDebitCardTransfersEditComponentCtrl,
  controllerAs: 'vm',
  template: acpDebitCardTransfersEditTemplate
};

export default acpDebitCardTransfersEditComponent;
